import {gql} from '@apollo/client';
import {getFormValues, reducer as formReducer} from 'redux-form';

import client from 'web/helpers/graphql_client';

export const ERROR_SAVING = 'PASSWORD_ERROR_SAVING';
export const FINISHED_SAVING = 'PASSWORD_FINISHED_SAVING';

export const savePassword = (input) =>
  client.mutate({
    mutation: gql`
      mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
          expires
        }
      }
    `,
    variables: {
      input: {newPassword: input},
    },
  });

export const actions = {
  save() {
    return async (dispatch, getState) => {
      const state = getState();
      const {newPassword} = getFormValues('password')(state);

      try {
        await savePassword(newPassword);

        dispatch(actions.finishSaving());
      } catch (error) {
        dispatch(actions.errorSaving(error.message));
      }
    };
  },
  errorSaving: (error) => ({
    value: error,
    type: ERROR_SAVING,
  }),
  finishSaving: () => ({
    type: FINISHED_SAVING,
  }),
};

export function reducer(state = {}, action = {}) {
  const form = formReducer(state.form, action);
  const newState = {...state, form};
  switch (action.type) {
    case ERROR_SAVING:
      return {...newState, form: {...form, globalError: action.value}};
    default:
      return newState;
  }
}
