import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';

import MarketLayout from 'web/components/market_layout';
import Panel from 'web/components/panel';

import AccountLayout from '../components/layout';
import {actions as passwordPageActions, reducer as passwordPageReducer} from './duck';
import PasswordConnectedForm from '../components/password_form';

class PasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSave() {
    this.props.save();
  }

  render() {
    return (
      <MarketLayout disableUpcomingOrdersBanner disableBasketDropdown>
        <Helmet>
          <title>Update Password | Good Eggs</title>
        </Helmet>

        <div className="change-password-page">
          <AccountLayout slug="password">
            <Panel header="Change your password">
              <div className="change-password-page__container">
                <PasswordConnectedForm onSubmit={this.onSave.bind(this)} label="New Password" />
              </div>
            </Panel>
          </AccountLayout>
        </div>
      </MarketLayout>
    );
  }
}

PasswordPage.propTypes = {
  dispatch: PropTypes.func,
  save: PropTypes.func,
  globalError: PropTypes.object,
};

PasswordPage.reducer = function (state, action) {
  const newState = MarketLayout.reducer(state, action);
  return passwordPageReducer(newState, action);
};

PasswordPage.pageName = 'Update Password';

export default connect((state) => state, passwordPageActions)(PasswordPage);
