import queryString from 'query-string';

import sanitizeReturnPath from 'web/helpers/routing/sanitize_return_path';

import {
  actions as passwordFormActions,
  reducer as passwordFormReducer,
} from '../components/password_form/duck';

export const actions = {
  save() {
    return function (dispatch) {
      dispatch(passwordFormActions.save())
        .then(function () {
          const {returnTo} = queryString.parse(window.location.search);
          window.location = sanitizeReturnPath({
            path: returnTo,
            defaultPath: '/account?confirmChanges=true',
          });
        })
        .catch(() => null); // form already displays error
    };
  },
};

export function reducer(state = {}, action = {}) {
  return passwordFormReducer(state, action);
}
